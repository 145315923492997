import request from '@/utils/request'


// 查询用户动态列表
export function listDynamic(query) {
  return request({
    url: '/user/user-dynamics/list',
    method: 'get',
    params: query
  })
}

// 查询用户动态分页
export function pageDynamic(query) {
  return request({
    url: '/user/user-dynamics/page',
    method: 'get',
    params: query
  })
}

// 查询用户动态详细
export function getDynamic(data) {
  return request({
    url: '/user/user-dynamics/detail',
    method: 'get',
    params: data
  })
}

// 新增用户动态
export function addDynamic(data) {
  return request({
    url: '/user/user-dynamics/add',
    method: 'post',
    data: data
  })
}

// 修改用户动态
export function updateDynamic(data) {
  return request({
    url: '/user/user-dynamics/edit',
    method: 'post',
    data: data
  })
}

// 删除用户动态
export function delDynamic(data) {
  return request({
    url: '/user/user-dynamics/delete',
    method: 'post',
    data: data
  })
}
export function batchApprove(data) {
  return request({
    url: '/user/user-dynamics/batchApprove',
    method: 'post',
    data: data
  })
}

<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="用户" prop="userId">
                <a-input v-model="queryParam.nickname" placeholder="请输入用户" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="内容" prop="content">
                <a-input v-model="queryParam.content" placeholder="请输入发布内容" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="审核状态" prop="sex">
                <a-select v-model="queryParam.approveStatus" placeholder="请选择" style="width: 100%" allow-clear>
                  <a-select-option :value="0">待审核</a-select-option>
                  <a-select-option :value="1">已审核</a-select-option>
                  <a-select-option :value="2">已驳回</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">

        <a-button type="primary" :disabled="multiple" @click="handleApprove"  >
          <a-icon type="edit" />批量审核通过
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <approve-modal ref='approveModal' @ok="getList"></approve-modal>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="photos" slot-scope="text, record">
           <template v-if="record.imgs">
            <img-preview v-for="item in record.imgs.split(',')" :src="item" :size="60"
                         style="margin-right: 8px;" />
          </template>
          <template v-else>未上传</template>
        </span>
        <span slot="realVideo" slot-scope="text, record">
            <template v-if="record.video">
                <video :src=" record.video" controls style="width:280px;height:200px" ></video>
              </template>
              <template v-else>未上传</template>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a v-if='record.approveStatus == 0' @click="$refs.approveModal.audit(record)"  >
            <a-icon type="edit" />审核
          </a>
          <a-divider type="vertical"   />
          <a @click="handleDelete(record)" >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageDynamic,listDynamic,batchApprove, delDynamic } from '@/api/user/dynamic'
import CreateForm from './modules/CreateForm'
import ApproveModal from './modules/ApproveModal.vue'
import { tableMixin } from '@/store/table-mixin'
import ImgPreview from '@/components/crud/table/ImgPreview.vue'
import Template from '@/views/sms/template/index.vue'

export default {
  name: 'Dynamic',
  components: {
    Template, ImgPreview,
    CreateForm,ApproveModal
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        nickname: null,
        content: null,

        approveStatus: null,
        approveContent: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '#',
          dataIndex: '',
          ellipsis: true,
          width: 30,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '用户 ',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '图片',
          dataIndex: 'imgs',
          // ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'photos' },
        },
        // {
        //   title: '视频',
        //   dataIndex: 'video',
        //   ellipsis: true,
        //   align: 'center',
        //   scopedSlots: { customRender: 'realVideo' },
        // },
        {
          title: '发布小区',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },

        // {
        //   title: '点赞数量',
        //   dataIndex: 'likeCount',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '审核状态',
          dataIndex: 'approveStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if(value == 0){
              return "待审核"
            }else if(value == 1){
              return "审核通过"
            }
            return "审核驳回"
          }
        },
        {
          title: '审核失败原因',
          dataIndex: 'approveContent',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发布时间',
          dataIndex: 'createTime',
          // scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户动态列表 */
    getList () {
      this.loading = true
     pageDynamic(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        nickname: undefined,
        content: undefined,

        approveStatus: undefined,
        approveContent: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleApprove (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认审核通过所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return batchApprove(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user-dynamic/export', {
            ...that.queryParam
          }, `用户动态_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
